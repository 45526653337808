import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})

export class RemoteNotificationService {
  private notificationsSource = new BehaviorSubject<any[]>(this.getNotifications());
  notifications = this.notificationsSource.asObservable();

  pusher: any;
  channel: any;

  constructor(private https: HttpClient) {
    console.log("Pusher created...");
    Pusher.logToConsole = true;

  this.pusher = new Pusher('9616fbb1352b74f7b3d9',{ cluster: "ap2" });
  this.channel = this.pusher.subscribe('gitco-pms-channel');
  
   }

   getNotifications(): any[] {
    const storedNotifications = localStorage.getItem('notifications');
    return storedNotifications ? JSON.parse(storedNotifications) : [];
  }

  addNotification(newNotification: any) {
    const notifications = this.getNotifications();
    notifications.unshift(newNotification);
    localStorage.setItem('notifications', JSON.stringify(notifications));
    this.notificationsSource.next(notifications);
  }


  clearAllNotifications() {
    localStorage.removeItem('notifications');
    this.notificationsSource.next([]);
  }

}

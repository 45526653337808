import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, merge, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../shared/delete/confirmation-dialog-component';
import { InvoiceModel } from './models/invoice-model';
import { InvoiceDataSource } from './data/invoice-data.source';
import { NotificationService } from '../shared/notification.service';
import { InvoiceService } from './data/invoice.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { LandlordService } from '../landlords/data/landlord.service';
import { selectorIsAgent, selectorIsLandlord, selectorUserID } from '../authentication/authentication.selectors';
import { UserSettingService } from '../settings/user/data/user-setting.service';
import { TenantService } from '../tenants/data/tenant.service';
import { USER_SCOPES } from '../shared/enums/user-scopes.enum';
import { AuthenticationService } from '../authentication/authentication.service';
import { MatTableExporterDirective, MatTableExporterModule } from 'mat-table-exporter';


@Component({
    selector: 'robi-utility-bills',
    templateUrl: './invoice.component.html',
    styleUrls: ['./invoice.component.scss']
})
export class PendingInvoiceComponent implements OnInit, AfterViewInit {

    displayedColumns = [
        'invoice_number',
        'invoice_date',
        'invoice_tenant',
        'lease_id',
        'period',
        'invoice_amount',
        'amount_paid',
        'amount_due',
        'due_date',
    ];

    loader = false;


    dataSource: InvoiceDataSource;

    // Search field
    @ViewChild('search') search: ElementRef;

    // pagination
    @ViewChild(MatPaginator, {static: true }) paginator: MatPaginator;
    @ViewChild(MatTableExporterDirective, { static: true }) exporter: MatTableExporterDirective;

    // Pagination
    length: number;
    pageIndex = 0;
    pageSizeOptions: number[] = [5, 10, 25, 50];
    meta: any;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    isAgent$: Observable<any>;
    isLandlord = false;
    landlordID: string;

    constructor(private store: Store<AppState>,
                private userService: UserSettingService,
                private landlordService: LandlordService,
                private tenantService: TenantService,
                private invoiceService: InvoiceService,
                private utilityBillService: InvoiceService,
                private notification: NotificationService,
                private authenticationService: AuthenticationService,
               ) {

  
    }

    /**
     * Initialize data lead
     * Set pagination data values
     * Initial data load
     */
    ngOnInit() {
        console.log('Inside Init....');
        this.dataSource = new InvoiceDataSource(this.invoiceService);
        this.dataSource.meta$.subscribe((res) => this.meta = res);
        this.dataSource.load('', 0, 0, 'updated_at', 'desc');

    }

    /**
     * Handle search and pagination
     */
    ngAfterViewInit() {
        fromEvent(this.search.nativeElement, 'keyup')
            .pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                tap(() => {
                    this.paginator.pageIndex = 0;
                    this.loadData();
                })
            ).subscribe();

        this.paginator.page.pipe(
            tap(() => this.loadData() )
        ).subscribe();

        // reset the paginator after sorting
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadData())
            )
            .subscribe();
    }

    /**
     * Fetch data from data lead
     */
    loadData() {

                this.dataSource.load(
                    this.search.nativeElement.value,
                    (this.paginator.pageIndex + 1),
                    (this.paginator.pageSize),
                    this.sort.active,
                    this.sort.direction
                );
               
    }

    /**
     * Empty search box
     */
    clearSearch() {
        this.search.nativeElement.value = '';
        this.loadData()
    }

   
    onSelected(invoice: InvoiceModel): void {
       // this.selectedRowIndex = invoice.id;
        this.invoiceService.changeSelectedInvoice(invoice);
    }


}

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { RemoteNotificationService } from '../notifications/notification.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public notifications: any[], private dialogRef: MatDialogRef<NotificationModalComponent>,
  private notificationService: RemoteNotificationService) { }

  ngOnInit(): void {
   // console.log(this.notifications);
  }

  clearAll() {
    this.notificationService.clearAllNotifications();
    this.notifications = []; // Update local notifications array
    this.dialogRef.close();
  }

}

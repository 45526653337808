import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { API_VERSION } from '../../../assets/config/api-version';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AuthActions } from '../../authentication/action-types';
import {
    selectorCompanyName,
    selectorIsAgent,
    selectorIsLandlord,
    selectorIsTenant,
    selectorUserScopes
} from '../../authentication/authentication.selectors';
import { Observable, of } from 'rxjs';
import { GeneralSettingService } from 'app/settings/general/data/general-setting.service';

declare const $: any;
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icon: string;
    collapse?: string;
    children?: ChildrenItems[];
    class: string;
    permission?: any;
}

interface ImageResponse {
    image: string;
  }

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    icon: string;
    type?: string;
    class: string;
    permission?: any;
}

export const ADMIN_ROUTES: RouteInfo[] = [
    { path: '/dashboard',         type: 'link',
    title: 'robi.sidebar.dashboard',  icon: 'dashboard', class: '', permission: ['view-dashboard'] },

    { path: '/crm',         type: 'sub',
    title: 'CRM',  icon: 'assignment_ind', class: '',
    permission: ['manage-setting'], 
    collapse: 'components',

    children: [
        {path: 'contacts', title: 'Contacts', icon: 'assignment_ind', ab:'',class: ''},
        {path: 'leads', title: 'Leads', ab:'',icon: 'assignment_ind',class: ''},
    ]    
},  


{ path: '/employees',        type: 'link',
title: 'Employees',  icon: 'recent_actors', class: '',
    permission: ['view-landlord', 'create-landlord', 'edit-landlord', 'delete-landlord'] },



    { path: '/landlords',        type: 'link',
    title: 'robi.sidebar.landlords',  icon: 'people_outline', class: '',
        permission: ['view-landlord', 'create-landlord', 'edit-landlord', 'delete-landlord'] },

    

    { path: '/properties',         type: 'link',
    title: 'robi.sidebar.properties',  icon: 'business', class: '',
        permission: ['view-property', 'create-property', 'edit-property', 'delete-property'] },

    { path: '/tenants',        type: 'link',
    title: 'robi.sidebar.tenants',  icon: 'group_add', class: '',
        permission: ['view-tenant', 'create-tenant', 'edit-tenant', 'delete-tenant'] },

    { path: '/leases',         type: 'link',
    title: 'robi.sidebar.leases',  icon: 'gavel', class: '',
        permission: ['view-lease', 'create-lease', 'edit-lease', 'delete-lease'] },

    { path: '/readings',         type: 'link',
    title: 'robi.sidebar.utilities',  icon: 'av_timer', class: '',
        permission: ['view-reading', 'create-reading', 'edit-reading', 'delete-reading'] },

    { path: '/maintenance',         type: 'link',
    title: 'robi.sidebar.maintenance',  icon: 'build', class: '',
        permission: ['view-lease', 'create-lease', 'edit-lease', 'delete-lease'] },


    { path: '',         type: 'sub',
    title: 'Accounts',  icon: 'local_atm', class: '',
    permission: ['manage-setting'], 
    collapse: 'components',

    children: [
        { path: 'invoices',         ab:'', type: 'link',
    title: 'Invoices',  icon: 'receipt', class: '',
        permission: ['view-invoice'] },

    { path: 'payments',        type: 'link',  ab:'',
    title: 'Payments',  icon: 'payment', class: '',
        permission: ['view-payment', 'create-payment'] }
        ,
    { path: 'expenses-booking', title: 'Expenses', type: 'link', icon: 'local_airport',ab:'', 
    class: '', permission: ['expense-add'] },

 

    ]    
}, 


    { path: '/notices',        type: 'link',
    title: 'Vacate Notice',  icon: 'lock_open', class: '',
        permission: ['view-notice', 'create-notice', 'edit-notice', 'delete-notice'] },

    { path: '/settings',         type: 'link',
    title: 'robi.sidebar.setting',  icon: 'settings', class: '',
        permission: ['manage-setting'] },

    { path: '/profile',        type: 'link',
    title: 'sidebar.profile',  icon: 'person', class: '',
        permission: ['view-profile'] },

    { path: '',         type: 'sub',
        title: 'General Reports',  icon: 'collections', class: '',
        permission: ['view-report'], 
        collapse: 'components',
    
        children: [
            { path: 'vacancy-report', title: 'Vacant Units', icon: 'assignment_ind', ab:'',class: ''},
            { path: 'pending-invoice-report', title: 'Pending Invoices', icon: 'assignment_ind', ab:'',class: ''},
            { path: 'collector-report', title: 'Cash Collections',  icon: 'payments',ab:'', class: '',  },
            { path: 'cheque-report', title: 'Cheque Collections',  icon: 'payments',ab:'', class: '',  },


        ]    
    },  

    { path: '/reports',         type: 'link',
    title: 'Finance Reports',  icon: 'attach_money', class: '',
        permission: ['view-report'] },

 
];



export const LANDLORD_ROUTES: RouteInfo[] = [
    { path: '/landlord/dashboard',        type: 'link',
    title: 'robi.sidebar.dashboard',  icon: 'dashboard', class: '', permission: ['am-landlord'] },

    { path: '/properties',        type: 'link',
    title: 'robi.sidebar.properties',  icon: 'business', class: '', permission: ['am-landlord'] },
    { path: '/leases',        type: 'link',
    title: 'robi.sidebar.leases',  icon: 'gavel', class: '', permission: ['am-landlord'] },
    { path: '/payments',         type: 'link',
    title: 'robi.sidebar.payments',  icon: 'payment', class: '', permission: ['am-landlord'] },
    { path: '/invoices',        type: 'link',
    title: 'robi.sidebar.invoices',  icon: 'receipt', class: '', permission: ['am-landlord'] },
    { path: '/notices',         type: 'link',
    title: 'Vacate Notice',  icon: 'lock_open', class: '', permission: ['am-landlord'] },
    { path: '/landlord/profile',        type: 'link',
    title: 'sidebar.profile',  icon: 'person', class: '', permission: ['am-landlord'] },
];



export const TENANT_ROUTES: RouteInfo[] = [
    { path: '/tenant/dashboard',        type: 'link',
    title: 'robi.sidebar.dashboard',  icon: 'dashboard', class: '', permission: ['am-tenant'] },
    { path: '/leases',         type: 'link',
    title: 'robi.sidebar.leases',  icon: 'gavel', class: '', permission: ['am-tenant'] },
    { path: '/payments',        type: 'link',
    title: 'robi.sidebar.payments',  icon: 'payment', class: '', permission: ['am-tenant'] },
    { path: '/notices',         type: 'link',
    title: 'Vacate Notice',  icon: 'lock_open', class: '', permission: ['am-tenant'] },
    { path: '/tenant/profile',        type: 'link',
    title: 'sidebar.profile',  icon: 'person', class: '', permission: ['am-tenant'] },
];

@Component({
  selector: 'robi-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent implements OnInit {
    private  localHttpClient: HttpClient;

  menuItemsAdmin: any[];
  menuItemsLandlord: any[];
  menuItemsContact: any[];
  menuItemsSales: any[];
  menuItemsTenant: any[];
    loading = false;
  showPhoto: SafeUrl;
    ;

    scopes$: any;
    isLandlord$: any;
    isTenant$: any;
    isAgent$: any;
    companyName: string;


    
  constructor(private store: Store<AppState>,public sanitizer: DomSanitizer, private auth: AuthenticationService,private generalService: GeneralSettingService) {
      this.scopes$ = this.store.pipe(select(selectorUserScopes));
      this.store.pipe(select(selectorCompanyName)).subscribe(name => this.companyName = name);
      this.isAgent$ = this.store.pipe(select(selectorIsAgent));
      this.isLandlord$ = this.store.pipe(select(selectorIsLandlord));
      this.isTenant$ = this.store.pipe(select(selectorIsTenant));
      
  }

  ngOnInit() {
    this.menuItemsAdmin = ADMIN_ROUTES.filter(menuItem => menuItem);
    //this.menuItemsSales = SALES_ROUTES.filter(menuItem => menuItem);

    this.menuItemsLandlord = LANDLORD_ROUTES.filter(menuItem => menuItem);

    this.menuItemsTenant = TENANT_ROUTES.filter(menuItem => menuItem);
    this.getImageFromService();

  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };

    /**
     * Logout user
     */
    logout() {
        this.loading = true;
        this.auth.logout()
            .pipe(
                tap(
                user => {
                    this.loading = false;
                    this.store.dispatch(AuthActions.actionLogout());
                }
            ))
            .subscribe(
                () => {},
                (error) => {
                    this.store.dispatch(AuthActions.actionLogout());
                    if (error.error.message) {
                    } else {
                    }
                    this.loading = false;
                });
    }

    getImageFromService() {
        this.generalService.fetchPhoto("6735abea-adf5-47ba-ab95-2b9b4b634f2f").subscribe((data: ImageResponse) => {
            this.showPhoto = this.sanitizer.bypassSecurityTrustResourceUrl(data.image);

        }, error => {
            console.error('Error fetching image:', error);
        });
    }
    


    createImageFromBlob(image: Blob) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            this.showPhoto = of(reader.result);
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

  

   
 
   

}

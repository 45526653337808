import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ConfirmationDialogComponent } from './shared/delete/confirmation-dialog-component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
  DefaultDataServiceConfig,
  EntityDataModule,
  HttpUrlGenerator,
} from '@ngrx/data';
import { JwtInterceptor } from './core/jwt.interceptor';
import { AppHttpUrlGenerator } from './core/app-http-url-generator';
import { ErrorInterceptor } from './core/error-handler/error.interceptor';
import { AuthenticationModule } from './authentication/authentication.module';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { StatementComponent } from './accounting/statement/statement.component';
import { PdfStatementComponent } from './accounting/pdf-statement/pdf-statement.component';
import { ExpenseBookingComponent } from './expense-booking/accounting.component';
import { AddExpenseComponent } from './expense-booking/expenses/add/add-expense.component';
import { EditExpenseComponent } from './expense-booking/expenses/edit/edit-expense.component';
import { AddPropertyExpenseComponent } from './expense-booking/property-expenses/add/add-expense.component';
import { EditPropertyExpenseComponent } from './expense-booking/property-expenses/edit/edit-expense.component';
import { AddSalaryExpenseComponent } from './expense-booking/salary-expenses/add/add-expense.component';
import { EditSalaryExpenseComponent } from './expense-booking/salary-expenses/edit/edit-expense.component';
import { ExpenseStatementComponent } from './expense-booking/statement/statement.component';
import { PdfExpenseStatementComponent } from './expense-booking/pdf-statement/pdf-statement.component';
import { ExpenseSettingComponent } from './settings/expense/expense-setting.component';
import { ExpenseCategorySettingComponent } from './settings/expense/expense_category/expense-category-setting.component';
import { AddExpenseCategoryComponent } from './settings/expense/expense_category/add/add-expense-category.component';
import { EditExpenseCategoryComponent } from './settings/expense/expense_category/edit/edit-expense-category.component';
import { RemoteNotificationService } from './notifications/notification.service';
import {VacancyReportComponent} from './vacancy-report/ledger.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { PendingInvoiceComponent } from './pending-invoices/invoice.component';
import { CollectorComponent } from './collector-report/collector.component';
import { ChequeComponent } from './cheque-report/collector.component';

import { FinanceComponent } from './accounting/finance/finance.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { PdfDocumentComponent } from './documents/pdf-document/pdf-document.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const defaultDataServiceConfig: DefaultDataServiceConfig = {
  root: 'http://localhost/rental/backend/api/v1/',
  timeout: 1000 * 60,
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    SharedModule,
    CoreModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    AuthenticationModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EntityDataModule.forRoot({}),
    MatTableExporterModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    ConfirmationDialogComponent,
    CollectorComponent,
    ChequeComponent,
    EditSalaryExpenseComponent,
    ExpenseStatementComponent,
    StatementComponent,
    PdfStatementComponent,
    ExpenseSettingComponent,
    ExpenseCategorySettingComponent,
    AddExpenseCategoryComponent,
    EditExpenseCategoryComponent,
    ExpenseBookingComponent,
    AddExpenseComponent,
    EditExpenseComponent,
    AddPropertyExpenseComponent,
    EditPropertyExpenseComponent,
    AddSalaryExpenseComponent,
  
    PdfExpenseStatementComponent,
    PdfDocumentComponent,
    VacancyReportComponent,
    PendingInvoiceComponent,
    
    FinanceComponent,
    NotificationModalComponent

    
  ],
  providers: [
   [RemoteNotificationService],
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HttpUrlGenerator, useClass: AppHttpUrlGenerator },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: DefaultDataServiceConfig, useValue: defaultDataServiceConfig } 
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor () {
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-accounting',
    templateUrl: './accounting.component.html',
    styleUrls: ['./accounting.component.css']
})
export class ExpenseBookingComponent implements OnInit {
    constructor() {
    }

    /**
     * Initialize data lead
     * Set pagination data values
     * Initial data load
     */
    ngOnInit() {
    }
}



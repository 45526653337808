import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { FinanceStatementService } from '../data/finance-statement.service';
import { FinanceStatementModel } from '../models/finance-statement.model';
import { select, Store } from '@ngrx/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {PropertyService} from '../../properties/data/property.service';
import { AppState } from '../../reducers';
import { ReportService } from '../data/report.service';
import { NotificationService } from '../../shared/notification.service';

@Component({
    selector: 'app-finance',
    templateUrl: './finance.component.html',
    styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {
    form: FormGroup;
    formErrors: any;

    properties: any;
    statementTypes: any;

    financeStatement: FinanceStatementModel;

    propertyId: any;
    now: any;
    loader = false;

    currentSettings$: any;
    businessName: any;

    constructor(private reportService: ReportService,private fb: FormBuilder, private store: Store<AppState>,
                private financeStatementService: FinanceStatementService, private propertyService: PropertyService,
                private dialog: MatDialog, private notification: NotificationService) {

        this.now = new Date();
        this.businessName ="GITCO Real Estate"
     
    }

    /**
     *
     */
    ngOnInit() {
        this.form = this.fb.group({
            start_date: [''],
            end_date: [moment()],
            statement_type_id: ['', [Validators.required,
                Validators.minLength(1)]],
            property_id: [this.propertyId, [Validators.required,
                Validators.minLength(1)]],
        });

        this.propertyService.list(['property_name'])
        .subscribe((res) => this.properties = res,
            () => this.properties = []
        );
        
        this.financeStatementService.list(['name', 'display_name'])
            .subscribe((res) => this.statementTypes = res,
                () => this.statementTypes = []
            );
    }

    /**
     *
     */
    downloadReport() {
        this.loader = true;
        const body = Object.assign({}, this.financeStatement, this.form.value);
        this.financeStatementService.downloadReport(body)
            .subscribe((res) => {
                    this.loader = false;
                    this.showFile(res);
                },
                () => {
                    this.loader = false;
                    this.notification.showNotification('danger', 'Error Downloading File!');
                }
            );
    }

    /**
     *
     * @param blob
     */
    private showFile(blob){
        let newBlob = new Blob([blob], {type: "application/pdf"});

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;
        link.download="report.pdf";
        link.click();
        setTimeout(function(){
            window.URL.revokeObjectURL(data);
        }, 100);
    }

}
